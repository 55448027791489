<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon> LISTA TIPO EVENTO OPCIONES
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon>Tipo evento opciones
            <strong class="ml-2"> #{{ eventTypeRequires.texto }} </strong>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>

    <create ref="eventForm" mode="edit"></create>
  </v-container>
</template>

<script>
import { showEventTypeRequiresOption } from "../../services/humansRights";
import Swal from "sweetalert2";
import Create from "./eventTypeRequiresOption/Create.vue";
export default {
  name: "editEventTypeRequires",
  data() {
    return {
      eventTypeRequires: {
        id: "",
        texto: "",
        value: "",
        event_type_requires_id: "",
      },
      eventId: "",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.eventId = id;
    try {
      this.showLoader();
      const eventShowResponse = await showEventTypeRequiresOption(id);
      const eventTypeRequires = {
        id: eventShowResponse.data.id,
        text: eventShowResponse.data.text,
        value: eventShowResponse.data.value,
        event_type_requires_id: eventShowResponse.data.event_type_requires_id,
      };

      this.eventTypeRequires = eventTypeRequires;

      this.$nextTick(() => {
        this.$refs.eventForm.setEventData(eventTypeRequires);
      });
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  components: { Create },
};
</script>

<style scoped></style>
